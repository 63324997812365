import React from "react"
import { Link } from "gatsby"
import NavigationBar from "../components/Navbar"
import SystemDesignCourseImg from "../img/system-design-course.svg"
import SystemDesignInterviewImg from "../img/system-design.png"
import { systemDesignCourseModuleList } from "../data/systemDesignCourseData"
import { Helmet } from "react-helmet"

import loadable from "@loadable/component"
const Footer2 = loadable(() => import("../components/footer3"))
const NewsletterStrip = loadable(() => import("../components/newsletterStrip"))
const SocialMedia = loadable(() => import("../components/social-media"))
const CoursesAndBlogs = loadable(() => import("../components/CoursesAndBlogs"))

const TableItem = ({ index, title, link }) => (
  <tr className="h-12 bg-white shadow-md border">
    <td className="px-2 py-2">
      <div className="flex items-center">
        <div className="flex w-6 h-6 text-sm sm:text-xs md:text-sm xl:text-base 2xl:text-lg items-center justify-center text-gray-900">
          {index}
        </div>
        <div className="ml-4">
          <div className="text-new-blue hover:underline text-sm sm:text-xs md:text-sm xl:text-base 2xl:text-lg">
            <Link to={link}>{title}</Link>
          </div>
        </div>
      </div>
    </td>
  </tr>
)

const ModuleTable = ({ moduleTitle, items }) => (
  <div className="bg-white">
    <h3 className="text-base sm:text-sm md:text-base text-center xl:text-lg 2xl:text-xl text-gray-900 tracking-wider">
      <span>{moduleTitle}</span>
    </h3>
    <table className="mt-4 w-full">
      <tbody>
        {items.map(({ chapterNumber, chapterName, path }) => (
          <TableItem
            key={chapterNumber}
            index={chapterNumber}
            title={chapterName}
            link={path}
          />
        ))}
      </tbody>
    </table>
  </div>
)

const SystemDesignCoursePage = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>System Design Course</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          property="og:description"
          content="A self-paced system design course by EnjoyAlgorithms to learn system design concepts, build data-intensive applications, and prepare for system design interviews."
        />
        <meta
          name="image"
          property="og:image"
          content={SystemDesignInterviewImg}
        />
        <meta name="author" content="Shubham Gautam" />
        <meta
          name="keywords"
          content="system design interview preparation, enjoyalgorithms system design course, system design online course, system design course enjoy algorithms, system design, system design course, system design online course, system design self-paced course, best system design interview preparation course"
        />
        <meta
          name="title"
          property="og:title"
          content=" System Design Interview Preparation Course"
        />
      </Helmet>
      <div className=" sm:sticky top-0 z-50">
        <NavigationBar />
      </div>

      <div className="bg-light-white">
        <div className="container mx-auto px-5 md:px-7 lg:px-8 xl:px-10">
          <div className="md:grid md:grid-cols-12">
            <div className="pt-4 md:pt-6 xl:pt-8 md:col-span-7">
              <h1 className="tracking-wider text-xl md:text-2xl xl:text-3xl text-gray-900 text-left sm:text-center md:text-left">
                System Design Interview Course
                <br />
              </h1>
              <p className="tracking-wider mt-3 sm:text-sm xl:text-base 2xl:text-lg text-gray-600 md:w-5/6">
                A free self-paced system design course for students and professionals
                who want to design large-scale systems, master system design
                problem solving, and prepare for tech interviews.
              </p>
              <h2 className="sm:hidden lg:block mt-6 tracking-wider text-gray-900 text-lg md:text-xl xl:text-2xl">
                What will you learn?
              </h2>
              <div className="mt-6 sm:hidden grid gap-6 sm:grid-cols-2 lg:grid text-gray-600 tracking-wider sm:text-sm xl:text-base 2xl:text-lg">
                <p> &#10003; System Design Fundamentals</p>
                <p> &#10003; Designing Scalable Systems</p>
                <p> &#10003; System Design Trade-offs</p>
                <p> &#10003; Solving Interview Questions</p>
              </div>
            </div>

            <div className="mt-6 md:mt-4 md:col-span-5 flex justify-center">
              <img
                className="object-cover pointer-events-none w-80 h-40 sm:w-112 sm:h-64 md:w-72 md:h-40 lg:w-96 lg:h-52 xl:w-112 xl:h-64 2xl:w-128 2xl:h-72"
                src={SystemDesignCourseImg}
                alt="enjoyalgorithms system design interview course"
              />
            </div>
          </div>

          <h2 className=" tracking-wider text-gray-900 mt-6 md:mt-2 hidden sm:block lg:hidden text-lg xl:text-2xl">
            What will you learn?
          </h2>

          <div className="mt-4 hidden tracking-wider sm:grid lg:hidden sm:text-sm xl:text-base 2xl:text-lg text-gray-600 gap-4 grid-cols-2">
            <p> &#10003; System Design Fundamentals</p>
            <p> &#10003; Designing Scalable Systems</p>
            <p> &#10003; System Design Trade-offs</p>
            <p> &#10003; Solving Interview Questions</p>
          </div>

          <div className="sm:flex sm:justify-between">
            <Link to="/shubham-gautam/">
              <div className="z-30 mt-4 sm:mt-6 text-center tracking-wider text-sm md:text-base 2xl:text-lg justify-end py-1 text-gray-600 hover:text-new-blue underline">
                <p> Mentor and Author: Shubham Gautam </p>
              </div>
            </Link>

            <div className="flex justify-end">
              <SocialMedia />
            </div>
          </div>

          <div className="mt-4 text-center sm:w-72">
            <a
              href="https://www.enjoyalgorithms.com/crack-system-design-interview/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="z-30 tracking-wider text-base lg:text-lg justify-center flex shadow px-4 py-2 rounded border border-new-purple text-white bg-white hover:bg-new-purple text-new-purple hover:text-white transition duration-150">
                <p> Try new look of this course </p>
              </div>
            </a>
          </div>

          <div className="mt-6 md:mt-8 xl:mt-10 grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 text-base sm:text-sm xl:text-base tracking-wider">
            {systemDesignCourseModuleList.map(module => (
              <ModuleTable
                key={module.moduleNumber}
                moduleTitle={module.moduleName}
                items={module.chapters}
              />
            ))}
          </div>

          <div className="z-30 mt-6 tracking-wider text-sm md:text-base 2xl:text-lg py-2 text-gray-600">
            <p>
              Note: In the coming future, we will publish more concept blogs
              related to databases, architecture, advanced algorithms and data
              structures, reliability, asynchronous processing, batch
              processing, etc. We are looking forward to your critical feedback
              and reviews. Enjoy system design!
            </p>
          </div>
          <div className="mt-6">
            <NewsletterStrip />
          </div>
          <div className="mt-6">
            <CoursesAndBlogs />
          </div>
          <Footer2 />
        </div>
      </div>
    </div>
  )
}

export default SystemDesignCoursePage
