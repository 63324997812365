export const systemDesignCourseModuleList = [
  {
    moduleNumber: 1,
    moduleName: "I. Basic Concepts",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "System Design Concept Guide",
        path: "/blog/basics-of-system-design/",
      },
      {
        chapterNumber: 2,
        chapterName: "System Design Interview Guide",
        path: "/blog/how-to-approach-system-design-interview/",
      },    
    {
        chapterNumber:3,
        chapterName: "Network Protocols",
        path: "/blog/network-protocols-concept/",
      },

      {
        chapterNumber: 4,
        chapterName: "Process Management in OS",
        path: "/blog/process-management-in-operating-system/",
      },
      {
        chapterNumber: 5,
        chapterName: "Latency of a System",
        path: "/blog/latency-in-system-design/",
      },
      {
        chapterNumber: 6,
        chapterName: "Throughput of a System",
        path: "/blog/throughput-in-system-design/",
      },
      {
        chapterNumber: 7,
        chapterName: "Availability of a System",
        path: "/blog/availability-system-design-concept/",
      },
      {
        chapterNumber: 8,
        chapterName: "Distributed System Concepts",
        path: "/blog/distributed-system-in-system-design/",
      },
      {
        chapterNumber: 9,
        chapterName: "Client Server Architecture",
        path: "/blog/client-server-architecture/",
      },
      {
        chapterNumber: 10,
        chapterName: "Peer-to-Peer Architecture",
        path: "/blog/peer-to-peer-networks/",
      },

    ],
  },
  {
    moduleNumber: 2,
    moduleName: "II. Advanced Concepts",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Load Balancers",
        path: "/blog/load-balancers-in-system-design/",
      },      
    {
        chapterNumber: 2,
        chapterName: "Load Balancing Algorithms",
        path: "/blog/types-of-load-balancing-algorithms/",
      },
      {
        chapterNumber: 3,
        chapterName: "Forward and Reverse Proxy",
        path: "/blog/proxies-in-system-design/",
      },

      {
        chapterNumber: 4,
        chapterName: "What is Websocket?",
        path: "/blog/web-sockets-in-system-design/",
      },
      {
        chapterNumber: 5,
        chapterName: "Server Sent Events",
        path: "/blog/server-sent-events/",
      },
      {
        chapterNumber: 6,
        chapterName: "HTTP Long Polling",
        path: "/blog/long-polling-in-system-design/",
      },

      {
        chapterNumber: 7,
        chapterName: "Consistent Hashing",
        path: "/blog/consistent-hashing-in-system-design/",
      },

      {
        chapterNumber: 8,
        chapterName: "Throttling and Rate limiting",
        path: "/blog/throttling-and-rate-limiting/",
      },

      {
        chapterNumber: 9,
        chapterName: "Publisher Subscriber Pattern",
        path: "/blog/publisher-subscriber-pattern/",
      },

      {
        chapterNumber: 10,
        chapterName: "Leader Election",
        path: "/blog/leader-election-system-design/",
      },
    ],
  },

  {
    moduleNumber: 3,
    moduleName: "III. Database Concepts",
    chapters: [
     {
        chapterNumber: 1,
        chapterName: "Fundamentals of Caching",
        path: "/blog/caching-system-design-concept/",
      },
      
     {
        chapterNumber: 2,
        chapterName: "Storage and Redundancy",
        path: "/blog/storage-and-redundancy/",
      },
      {
        chapterNumber: 3,
        chapterName: "Database Partitioning",
        path: "/blog/data-partitioning-system-design-concept/",
      },

      {
        chapterNumber: 4,
        chapterName: "Database Replication",
        path: "/blog/introduction-to-database-replication-system-design/",
      },

      {
        chapterNumber: 5,
        chapterName: "Master-Slave Replication",
        path: "/blog/master-slave-replication-databases/",
      },
     {
        chapterNumber: 6,
        chapterName: "SQL vs NoSQL Databases",
        path: "/blog/sql-vs-nosql/",
      },

      {
        chapterNumber: 7,
        chapterName: "CAP Theorem in DBMS",
        path: "/blog/cap-theorem-in-system-design/",
      },

      {
        chapterNumber: 8,
        chapterName: "Key-Value Database",
        path: "/blog/key-value-stores-in-system-design/",
      },

      {
        chapterNumber: 9,
        chapterName: "Graph Database",
        path: "/blog/graph-database-system-design/",
      },

      {
        chapterNumber: 10,
        chapterName: "SQL for Data Science",
        path: "/blog/introduction-to-sql-for-data-science/",
      },
    ],
  },

  {
    moduleNumber: 4,
    moduleName: "IV. Basic Interview Questions",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Design LRU Cache",
        path: "/blog/implement-least-recently-used-cache/",
      },
      {
        chapterNumber: 2,
        chapterName: "Design LFU Cache",
        path: "/blog/least-frequently-used-cache/",
      },
      {
        chapterNumber: 3,
        chapterName: "Design Bloom Filter",
        path: "/blog/bloom-filter/",
      },
      {
        chapterNumber: 4,
        chapterName: "Design Key-Value Store",
        path: "/blog/design-key-value-store/",
      },
      {
        chapterNumber: 5,
        chapterName: "Pastebin System Design",
        path: "/blog/design-pastebin-system-design-interview-question/",
      },
      {
        chapterNumber: 6,
        chapterName: "Typeahead System Design",
        path: "/blog/design-typeahead-system/",
      },
      {
        chapterNumber: 7,
        chapterName: "URL Shortener System Design",
        path: "/blog/design-a-url-shortening-service-like-tiny-url/",
      },
      {
        chapterNumber: 8,
        chapterName: "Design Notification Service",
        path: "/blog/notification-service/",
      },
      {
        chapterNumber: 9,
        chapterName: "Design QR Code Generator",
        path: "/blog/design-qr-code-system/",
      },
      {
        chapterNumber: 10,
        chapterName: "Design API Rate Limiter",
        path: "/blog/design-api-rate-limiter/",
      },
    ],
  },

  {
    moduleNumber: 5,
    moduleName: "V. Advanced Interview Questions",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Web Crawler System Design",
        path: "/blog/web-crawler/",
      },
      {
        chapterNumber: 2,
        chapterName: "Youtube System Design",
        path: "/blog/design-youtube-system-design-interview-question/",
      },
      {
        chapterNumber: 3,
        chapterName: "WhatsApp System Design",
        path: "/blog/design-whatsapp/",
      },
      {
        chapterNumber: 4,
        chapterName: "Google Docs System Design",
        path: "/blog/design-google-docs/",
      },
      {
        chapterNumber: 5,
        chapterName: "Instagram System Design",
        path: "/blog/design-instagram/",
      },
      {
        chapterNumber: 6,
        chapterName: "Twitter System Design",
        path: "/blog/design-twitter/",
      },
      {
        chapterNumber: 7,
        chapterName: "Dropbox System Design",
        path: "/blog/design-dropbox/",
      },
      {
        chapterNumber: 8,
        chapterName: "Yelp System Design",
        path: "/blog/design-yelp/",
      },
      {
        chapterNumber: 9,
        chapterName: "Uber System Design",
        path: "/blog/design-uber/",
      },
      {
        chapterNumber: 10,
        chapterName: "Recommender System",
        path: "/blog/recommender-system-using-machine-learning/",
      },
    ],
  },
]